<template>
  <v-container fluid>
    <BreadHeader :title="`Add Event Category`">
      <v-btn
        :color="HRDC_BLUE_THEME_COLOR"
        class="white--text"
        @click="toggleEventCategoryPage()"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
    </BreadHeader>

    <AError :api="apiSubmit" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-dialog v-model="eventCategoryDialog" max-width="500">
      <v-card>
        <v-toolbar :color="HRDC_BLUE_THEME_COLOR" dark>
          Add Event Category
        </v-toolbar>

        <div class="pa-4">
          Please confirm that you wish to Add New Event Category ?
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green lighten-1"
            class="text-light"
            @click="addNewEventCategory()"
          >
            Submit
          </v-btn>

          <v-btn
            color="red lighten-1"
            class="text-light"
            @click="eventCategoryDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <div>
        <!-- Category -->
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            dense
            filled
            label="Category"
            placeholder="e.g. E-INVOICING CONFERENCE 2024"
            v-model="formData.eventCategory"
            :error-messages="errorField.eventCategory"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            class="ma-2"
            color="success"
            @click="eventCategoryDialog = true"
            :loading="apiSubmit.isLoading"
          >
            Add
          </v-btn>
        </v-col>
      </div>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Api from "@/objects/api";
import Model from "@/objects/model";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import HrdcData from "@/services/hrdc_tax_summit/objects/globalData";

export default {
  mixins: [hrdcFunction],
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  emits: ["toggleEventCategoryPage"],
  data: () => ({
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    formData: {
      eventCategory: null,
    },
    errorField: {
      eventCategory: null,
    },
    loadingDialog: false,
    eventCategoryDialog: false,
    editMode: false,
    btnDisabled: false,
    eventType: null,
    eventCategoriesData: [],
  }),
  async created() {
    this.showLoadingDialog();

    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    this.eventType = this.EVENT_TYPE[this.service.key] || null;

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/settings/event/create-event-category`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    //EOC
    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);
    if (!this.isHrdcSuperAdminRoles) {
      this.alertError.push("You are not allowed to perform this action!");
      this.btnDisabled = true;
    }

    this.hideLoadingDialog();
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    addNewEventCategory() {
      this.eventCategoryDialog = !this.eventCategoryDialog;
      this.submit();
    },
    toggleEventCategoryPage() {
      this.$emit("toggleEventCategoryPage", "EventCategoryIndex");
    },
    redirectBack() {
      let route = "HrdcTaxSummitApplication";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$store.commit("assignDeveloperData", {
        paginationPageNo: this.$route.query.currentPageNo,
      });

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
        query: {
          viewId: this.$store.state.hrdc_tax_summit.data.viewId,
        },
      });
    },
    async submit() {
      this.showLoadingDialog();

      this.apiSubmit.setParams({
        eventCategory: this.formData.eventCategory,
        eventType: this.eventType,
      });
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: "Please fill in all the required fields",
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$emit("toggleEventCategoryPage", "EventCategoryIndex");
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
      this.hideLoadingDialog();
    },
  },
};
</script>
